<template>
    <v-container class="no-gutters my-0 py-0">

        <div  v-if="showFeedForward"
              class="game-component">
            <div
                class="ql-editor"
                style="padding: 15px 0px"
                v-html="getFeedForwardLang"
            />

            <div class="d-flex justify-center flex-wrap mt-4">

                <span>
                    <v-btn
                        class="mr-2"
                        outlined
                        x-large
                        fab
                        color="primary"
                        @click="startGame"
                    >
                        <v-icon>mdi-play</v-icon>
                    </v-btn>

                    {{$t('games.start')}}
                </span>

            </div>

        </div>
        <div
            v-else
            class="game-component"
        >
            <jumanjiGame
                v-if="gameTypeHelper.isJumanji(game)"
                :game-id="gameId"
                :teams_count="game.teams_count"
            />
            <questionsGame
                v-else-if="gameTypeHelper.isQuestions(game)"
                :game-id="gameId"
            />
        </div>
    </v-container>
</template>

<script>
import axios from '@/plugins/axios'
import {mapGetters} from "vuex";

export default {
    name: 'GameComponnent',
    components: {
        jumanjiGame: () => import('@/components/games/jumanji/jumanjiComponnent'),
        questionsGame: () => import('@/components/games/questions/questionsComponnent')

    },
    props: {
        gameId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            urlMain: 'games/games/' + this.gameId,
            game: {
                id: '',
                game_type_id: '',
                teams_count: '',
                feedforward: null
            },
            showFeedForward: false
        }
    },
    computed: {
        getFeedForwardLang(){
            return this.game[`feedforward_${this.getLang}`];
        },
        ...mapGetters("languages", ['getLang'])
    },
    created() {
        this.fetchGame();
    },
    methods: {

        async fetchGame() {
            try {
                const game = await axios
                    .get(this.urlMain);

                this.initialize(game.data)
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize(data) {

            this.showFeedForward  = !!data.game[`feedforward_${this.getLang}`];

            this.game = data.game;

        },
        startGame(){
            this.showFeedForward = false;
        }
    }
}
</script>

<style scoped>
.game-component{
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    min-height: 50vh;
}

</style>
